<template>
  <div class="container mt-3 wrapper">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h3>배정 그룹 등록 및 현황​</h3>
    </div>
    <div class="mt-4">
      <table class="info">
        <tbody>
          <tr>
            <td class="w-20per txt-center label p-2">진단기간</td>
            <!-- <td class="w-75per"><Datepicker /> ~ <Datepicker /></td>   -->
            <td class="w-75per inputPadding">
              <b-form-input class="w-20per inli" @click="dateClick($event)" v-model="state.groupValues.groupStartData" type="date"></b-form-input> ~
              <b-form-input class="w-20per inli" @click="dateClick($event)" v-model="state.groupValues.groupEndData" type="date"></b-form-input>
            </td>
          </tr>
          <tr>
            <td class="txt-center label p-2">배정 그룹명</td>
            <td class="inputPadding">
              <b-form-input class="w-20per" v-model="state.groupValues.groupName" type="text"></b-form-input>
            </td>
          </tr>
          <tr>
            <td class="txt-center label p-2">
              발송 안내
              <!-- <p class="subText">* 메일, SMS 등 노출 예정</p> -->
            </td>

            <td class="contact-information-form">
              <span></span>
              <div>
                <p class="inli txt-center">매니저명 :&nbsp;&nbsp;</p>
                <b-form-input class="w-20per inli" v-model="state.groupValues.groupManagerName" type="text"></b-form-input>
              </div>
              <span></span>
              <div>
                <p class="inli">메일제목 :&nbsp;&nbsp;</p>
                <b-form-input class="w-20per inli" v-model="state.groupValues.groupManagerEmailTtile" type="text"></b-form-input>
              </div>
              <span></span>

              <div class="txtRed">※ 이메일은 "ktng_leadership@carrotglobal.com" 으로 발송됩니다.</div>
              <div class="txtRed">※ 문자는 "02-518-5467" 으로 발송됩니다.</div>
              <span></span>

              <!-- <div>
                <p class="inli txt-center">전화번호 :&nbsp;&nbsp;</p>
                <b-form-input class="w-20per inli" v-model="state.groupValues.groupManagerPhone" type="text"></b-form-input>
              </div>
              <br />
              <div>
                <p class="inli txt-center">이메일&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;</p>
                <b-form-input class="w-20per inli" v-model="state.groupValues.groupManagerEmail" type="text"></b-form-input>
              </div>
              <br /> -->
            </td>
          </tr>
          <tr>
            <td class="txt-center label p-2">진단 대상자 <br />진단자 등록 및 매칭</td>
            <td class="inputPadding">
              엑셀 일괄등록 :
              <input type="file" ref="excelFile" @change="changeFile($event)" /> <br />
              <span class="txtRed">※ 등록 오류시 개별 삭제만 가능하며, 엑셀로 추가 등록만 가능합니다.</span>
              &nbsp;&nbsp;&nbsp;<span class="txtBlue pointer" @click="sampleExcelDwonLoad">(양식 다운로드)</span><br />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="txt-center">
        <b-button pill :variant="state.insertChk ? 'primary' : 'warning'" :disabled="!state.insertChk" class="btnCl" @click="insertGroup">
          {{ state.insertChk ? (state.isModify ? '수정하기' : '생성하기') : '생성중입니다...' }}
        </b-button>
        <!-- <b-button v-if="state.isModify" pill variant="primary" class="btnCl" @click="insertGroup">수정하기</b-button>
        <b-button v-else pill variant="primary" class="btnCl" @click="insertGroup">생성하기</b-button> -->
        <!-- <b-button v-else pill variant="info"  class="btnCl" @click="insertGroup">생성하기</b-button> -->
        <!-- <b-button variant="primary" v-else @click ="searchList">검색</b-button> -->
      </div>
    </div>

    <div class="mt-5">
      <table class="ta_info">
        <tbody>
          <tr>
            <th>진단 대상자 수</th>
            <th>진단 진행</th>
            <th>진단 현황</th>
          </tr>
          <tr>
            <td>{{ state.statistics.statisticsSubjectCnt }}</td>
            <td>{{ state.statistics.statisticsListCnt }}</td>
            <td>{{ state.statistics.statisticsCompleteCnt }} / {{ state.statistics.statisticsListCnt }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 테이블 -->
    <article>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="전체" active value="전체" @click="clickTab($event)"></b-tab>
          <b-tab title="진단대상자별" value="진단대상자별" @click="clickTab($event)"></b-tab>
        </b-tabs>
      </div>
      <div class="search-box mb-4">
        <b-row class="d-flex mb-4">
          <b-col cols="2" v-if="state.searchObj.searchMenu != '진단대상자별'">
            <select class="form-select fl" v-model="state.searchObj.searchStatus" @change="searchList($event)">
              <option value="">전체상태</option>
              <option value="미진단">미진단</option>
              <option value="진단중">진단중</option>
              <option value="진단완료">진단</option>
            </select>
          </b-col>
          <b-col cols="2" v-if="state.searchObj.searchMenu != '진단대상자별'">
            <select class="form-select fl" v-model="state.searchObj.searchType" @change="chagneFilter($event)">
              <option value="diag_subject_name">진단대상자</option>
              <option value="relationship">관계</option>
              <option value="diag_subject_part">소속부서</option>
              <option value="diag_mobile">핸드폰번호</option>
              <option value="diag_subject_id">진단대상자ID</option>
              <option value="diag_name">진단자</option>
              <option value="diag_id">진단자ID</option>
              <option value="diag_subject_mobile">진단자 핸드폰번호</option>
              <option value="diagnostic_code">진단코드</option>
            </select>
          </b-col>
          <b-col cols="2" v-if="state.searchObj.searchMenu == '진단대상자별'">
            <select class="form-select fl" v-model="state.searchObj.searchType" @change="chagneFilter($event)">
              <option value="diag_subject_name">진단대상자</option>
              <option value="diag_subject_id">진단대상자ID</option>
            </select>
          </b-col>
          <b-col cols="3">
            <input type="text" v-model="state.searchObj.searchText" class="form-control fl" />
          </b-col>
          <b-col cols="5">
            <b-button variant="primary" v-if="state.searchObj.searchMenu == '진단대상자별'" @click="searchSubhList">검색</b-button>
            <b-button variant="primary" v-else @click="searchList">검색</b-button>
          </b-col>
        </b-row>
        <b-row class="d-flex mb-4">
          <div class="d-flex justify-content-end">* 미진단자 재전송은 메일, 문자로 모두 발송됩니다.</div>
          <b-col cols="12" class="d-flex justify-content-end text-pd">
            <b-button v-if="state.searchObj.searchMenu == '전체'" variant="outline-danger" class="me-3" @click="deleteItem">삭제</b-button>
            <b-button variant="outline-success" @click="excelDownLoad()" class="me-3">엑셀 다운로드</b-button>
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-info'"
              @click="setAnnouncementModal('메일', '진단', '')"
              class="me-3"
              >메일 안내 발송</b-button
            >
            <!-- <b-button v-if="state.searchObj.searchMenu == '전체'" variant="outline-primary" @click="setAnnouncementModal('문자', '진단')" class="me-3">문자 안내 발송</b-button> -->
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-primary'"
              @click="setMessageModal(true)"
              class="me-3"
              >문자 안내 발송</b-button
            >
            <b-button
              v-if="state.searchObj.searchMenu == '전체'"
              :disabled="state.diagnosisList.length === 0"
              :variant="state.diagnosisList.length === 0 ? 'secondary' : 'outline-secondary'"
              @click="setAnnouncementModal('미진단', '미진단', '')"
              class="me-3"
              >미진단자 재전송</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <table class="table table-hover text-center font-set" v-if="state.searchObj.searchMenu == '전체'">
            <thead>
              <tr>
                <th><input type="checkbox" @click="allChkBox($event)" v-model="state.allCheck" class="cursor" /></th>
                <th scope="col">No</th>
                <th scope="col">관계</th>
                <th scope="col">소속부서</th>
                <th scope="col">진단대상자</th>
                <th scope="col">진단대상자ID</th>
                <!-- <th scope="col">진단대상자 핸드폰번호</th> -->
                <th scope="col">진단자</th>
                <th scope="col">진단자ID</th>
                <th scope="col">진단자 핸드폰번호</th>
                <th scope="col">진단코드</th>
                <th scope="col">현황</th>
                <th scope="col">진단완료일</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-if="state.diagnosisList == 0">
                <td colspan="13">정보가 없습니다.</td>
              </tr>
              <tr v-for="item in state.diagnosisList" :key="item.index">
                <th><input type="checkbox" v-model="item.isChecked" @click="clickChkBox(item.idx, $event)" class="cursor" /></th>
                <td>{{ item.rowNum }}</td>
                <td>{{ item.relationship }}</td>
                <td>{{ item.diag_subject_part }}</td>
                <td>{{ item.diag_subject_name }}</td>
                <td>{{ item.diag_subject_id }}</td>
                <!-- <td>{{ item.diag_subject_mobile }}</td> -->
                <td>{{ item.diag_name }}</td>
                <td>{{ item.diag_id }}</td>
                <td>{{ addStarNumber(item.diag_mobile) }}</td>
                <td>{{ item.diagnostic_code }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.diag_end_date }}</td>
              </tr>
            </tbody>
          </table>
          <table class="table table-hover text-center font-set" v-if="state.searchObj.searchMenu == '진단대상자별'">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">진단대상자</th>
                <th scope="col">진단대상자ID</th>
                <th scope="col">현황</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              <tr v-if="state.diagnosisList == 0">
                <td colspan="12">정보가 없습니다.</td>
              </tr>
              <tr v-for="(item, index) in state.diagnosisSubList" :key="item.index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.diag_subject_name }}</td>
                <td>{{ item.diag_subject_id }}</td>
                <td>{{ item.diag_success_total + '/' + item.diag_total }}</td>
              </tr>
            </tbody>
          </table>

          <b-row>
            <b-col cols="12" class="d-flex justify-content-center">
              <div class="mt-3">
                <Paging :totaldata="state.pagingObj.pagingTotal" :pagingdata="50" :pagingrange="5" :setPage="setPage" @onPagingEvent="onMovePageing"></Paging>
                <!-- 
              totaldata : 총 리스트 갯수 
            -->
              </div>
            </b-col>
          </b-row>
        </b-row>
      </div>
      <MessageModal
        v-if="state.isMessageModal"
        :groupValues="state.groupValues"
        :diagnosisList="state.diagnosisList"
        :checkList="state.checkList"
        :visible="state.isMessageModal"
        :isAnnouncementModal="state.isAnnouncementModal"
        @sendMassage="setAnnouncementModal"
        @closeModal="setMessageModal(false)"></MessageModal>
      <AnnouncementModal
        v-if="state.isAnnouncementModal"
        :count="state.checkList"
        :kind="state.announcementType"
        :visible="state.isAnnouncementModal"
        @closeModal="state.isAnnouncementModal = false"
        @sendMassage="guideSend(announcementType)"></AnnouncementModal>
    </article>
  </div>
</template>

<script>
import { onMounted, watch } from '@vue/runtime-core';
import { ref, reactive } from '@vue/reactivity';
import { _axios } from '@/utils/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import AnnouncementModal from '@/components/AnnouncementModal.vue';
import MessageModal from '@/components/MessageModal.vue';
import { faSlideshare } from '@fortawesome/free-brands-svg-icons';
export default {
  name: 'Assignment',
  components: {
    AnnouncementModal,
    MessageModal,
  },
  setup() {
    const setPage = ref(1);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      diagnosisList: [],
      diagnosisSubList: [],
      allCheck: false,
      checkList: new Set(),
      isModify: false,
      ResistIdx: '',
      groupValues: {
        groupStartData: '',
        groupEndData: '',
        groupName: '',
        groupManagerName: '',
        // groupManagerPhone: '02-518-5467',
        // groupManagerEmail: 'ktng_leadership@carrotglobal.com​',
        groupManagerEmailTtile: '',
        groupExcelFile: '',
      },
      statistics: {
        statisticsListCnt: 0, // 진단 진행
        statisticsSubjectCnt: 0, // 진단 대상자수
        statisticsCompleteCnt: 0, // 진단완료
      },
      searchObj: {
        searchMenu: '전체',
        searchNameList: new Set(),
        searchSelectName: '',
        searchText: '',
        searchStatus: '',
        searchType: 'diag_subject_name',
      },
      pagingObj: {
        pagingTotal: 50,
        pageNum: 1,
        pageSize: 50,
      },
      insertChk: true,
      sendChk: true,
      isAnnouncementModal: false, // 안내 모달의 on / off
      announcementType: '메일', // 안내 모달의 타입 + 메일, 문자, 미진단 구분
      isMessageModal: false, // 문자 전송 모달 on / off
      diagnosisType: '진단', // 진단 인지 미진단인지 확인
      messageText: '',
    });
    let tempExcel = '';

    const addStarNumber = phoneNumber => {
      const splitPhoneNumber = phoneNumber.split('-');
      return `${splitPhoneNumber[0]}-${splitPhoneNumber[1]}-****`;
    };

    const setMessageModal = aValue => {
      state.isMessageModal = aValue;
    };

    async function getList(idx_leadership_group) {
      let tempobj = {
        idx_leadership_group,
        pageNum: state.pagingObj.pageNum,
        status: state.searchObj.searchStatus,
      };
      tempobj[state.searchObj.searchType] = state.searchObj.searchText;
      await _axios
        .get(`/admin/diag/list`, {
          params: tempobj
        })
        .then(res => {
          state.pagingObj.pagingTotal = res.data.data.total;
          const { list } = res.data.data;
          state.diagnosisList = list;
          state.searchObj.searchNameList.clear();
          state.allCheck = false;
          state.diagnosisList.forEach(el => {
            el.isChecked = false;
            state.searchObj.searchNameList.add(el.diag_subject_name);
          });
        })
        .catch(err => {
          alert(`getList err : ${err}`);
        });
    }
    async function getSubjectList(idx_leadership_group) {
      state.pagingObj.pageNum = 1;
      let tempobj = {
        idx_leadership_group,
        pageNum: state.pagingObj.pageNum,
      };
      tempobj[state.searchObj.searchType] = state.searchObj.searchText;
      await _axios
        .get(`/admin/diag/subjectList`, {
          params: tempobj
        })
        .then(res => {
          setPage.value = 1;
          state.pagingObj.pagingTotal = res.data.data.total;
          const { list } = res.data.data;
          state.diagnosisSubList = list;
        })
        .catch(err => {
          alert(`subjectList err : ${err}`);
        });
    }

    function allChkBox($event) {
      if ($event.target.checked == true) {
        state.allCheck = true;
        state.diagnosisList.forEach(el => {
          state.checkList.add(el.idx);
          el.isChecked = true;
        });
      } else {
        state.allCheck = false;
        state.checkList.clear();
        state.diagnosisList.forEach(el => {
          el.isChecked = false;
        });
      }
    }

    function clickChkBox(idx, $event) {
      if ($event.target.checked == false) {
        state.allCheck = false;
        state.checkList.delete(idx);
        state.diagnosisList.forEach(el => {
          if (idx == el.idx) el.isChecked = false;
        });
      } else {
        state.checkList.add(idx);
        state.diagnosisList.forEach(el => {
          if (idx == el.idx) el.isChecked = true;
        });
        let chkAllCheck = state.diagnosisList.find(el => el.isChecked == false);
        if (chkAllCheck == undefined) {
          state.allCheck = true;
        }
      }
    }

    async function getGroup(idx) {
      await _axios
        .get(`/admin/group/${idx}`, {})
        .then(res => {
          const { data } = res.data;
          state.groupValues.groupManagerName = data.contact_manager;
          // state.groupValues.groupManagerPhone = '02-518-5467';
          // state.groupValues.groupManagerEmail = 'ktng_leadership@carrotglobal.com​';
          state.groupValues.groupManagerEmailTtile = data.contact_title;
          state.groupValues.groupEndData = data.end_date;
          state.groupValues.groupName = data.group_name;
          state.groupValues.groupStartData = data.start_date;
        })
        .catch(err => {
          if (route.query.uid == undefined) {
            alert('인트라넷을 통해 접속해주세요');
            window.location.href = `http://carrotians.net/`;
          } else {
            alert(`group err : ${err}`);
          }
        });
    }

    async function getGroupList(idx) {
      await _axios
        .get(`/admin/group/list`, {
          params: {
            idx,
            pageNum: 1,
          }
        })
        .then(res => {
          const { list } = res.data.data;
          state.statistics.statisticsListCnt = list[0].diagListCnt;
          state.statistics.statisticsSubjectCnt = list[0].diagSubjectCnt;
          state.statistics.statisticsCompleteCnt = list[0].diagCompleteCnt;
        })
        .catch(err => {
          alert(`group/list err : ${err}`);
        });
    }

    const insertGroup = async () => {
      if (state.groupValues.groupStartData > state.groupValues.groupEndData) {
        alert('진단기간을 확인해주세요');
        return;
      }
      const formData = new FormData();
      formData.append('file', tempExcel);
      let data = {
        idx: route.query.idx == undefined ? '' : route.query.idx,
        // contact_email: state.groupValues.groupManagerEmail,
        contact_manager: state.groupValues.groupManagerName,
        // contact_mobile: state.groupValues.groupManagerPhone,
        contact_title: encodeURIComponent(state.groupValues.groupManagerEmailTtile),
        end_date: state.groupValues.groupEndData,
        group_name: encodeURIComponent(state.groupValues.groupName),
        start_date: state.groupValues.groupStartData,
      };
      if (state.insertChk) {
        state.insertChk = false;
        await _axios
          .post(`/admin/group`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            params: data,
            timeout: 50000,
          })
          .then(res => {
            state.insertChk = true;
            if (res.data.success == true) {
              if (route.query.idx != undefined) {
                alert('수정이 완료되었습니다.');
                getList(state.ResistIdx);
                getGroupList(state.ResistIdx);
              } else {
                alert('등록이 완료되었습니다.');
                state.ResistIdx = res.data.data.idx;
                router.push({
                  name: `Assignment`,
                  query: { idx: res.data.data.idx },
                });
                getList(state.ResistIdx);
                getGroupList(state.ResistIdx);
                initInputData();
              }
            } else {
              state.insertChk = true;
              alert(`${res.data.message}`);
            }
          })
          .catch(err => {
            state.insertChk = true;
            alert(`admin/group err = ${err}`);
          });
      }
    };

    function changeFile($event) {
      tempExcel = $event.target.files[0];
    }

    async function deleteItem() {
      let chkState = false;
      let chkconfirm = false;
      if (state.allCheck && state.searchObj.searchStatus == '' && state.searchObj.searchText == '') {
        //전체삭제
        if (window.confirm(`전체 삭제 하시겠습니까?`)) {
          if (window.confirm(`등록된 모든 데이터가 삭제 됩니다`)) {
            await _axios
              .delete(`/admin/diag/deleteAll/${state.ResistIdx}`, {})
              .then(() => {
                alert(`전체삭제가 완료되었습니다.`);
              })
              .then(() => {
                getList(state.ResistIdx);

                getGroupList(state.ResistIdx);
              });
          }
        }
      } else {
        state.diagnosisList.forEach(el => {
          state.checkList.forEach(element => {
            if (element == el.idx) {
              // API 에서 받아온 리스트와 체크박스 체크된 리스트와 동일한 경우
              if (el.status == '진단중' || el.status == '진단완료') {
                chkState = true;
              }
            }
          });
        });
        if (chkState) {
          chkconfirm = window.confirm(`진행중 또는 진행완료 된 건들이 포함 되어 있습니다.\n정말 삭제하시겠습니까?`);
        } else {
          chkconfirm = window.confirm(`총 ${state.checkList.size}건 삭제하시겠습니까? `);
        }
        if (chkconfirm) {
          for (let item of state.checkList) {
            await _axios
              .delete(`/admin/diag/delete/${item}`, {})
              .catch(err => {
                console.log(err);
              });
          }
          alert(`총 ${state.checkList.size}건 삭제가 완료되었습니다.`);
        }
        getList(state.ResistIdx);
        getGroupList(state.ResistIdx);
        state.checkList.clear();
      }
    }

    function sampleExcelDwonLoad() {
      // // url 추가해줄것 master
      let url = '';
      if (location.origin.includes('local') || location.origin.includes('stage')) {
        url = `http://stage-api.ktngleader.com/admin/diag/download-object?fileName=example.xlsx`;
      } else {
        url = `https://api.ktngleader.com/admin/diag/download-object?fileName=example.xlsx`;
      }
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'SampleExcel.xlsx';
          link.click();
        });
    }
    async function excelDownLoad() {
      const FileDownload = require('js-file-download');
      let targetList = '';
      if (state.searchObj.searchMenu == '진단대상자별') {
        let tempobj = {
          idx_leadership_group: state.ResistIdx,
          status: state.searchObj.searchStatus,
          idx: state.allCheck ? '' : targetList,
        };
        tempobj[state.searchObj.searchType] = state.searchObj.searchText;
        let locationUrl = '';
        // if(!state.allCheck) {
        if (location.origin.includes('local') || location.origin.includes('stage')) {
          // locationUrl = `http://stage-api.ktngleader.com/admin/diag/subjectListExcel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
          locationUrl = `https://api.ktngleader.com/admin/diag/subjectListExcel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
        } else {
          locationUrl = `https://api.ktngleader.com/admin/diag/subjectListExcel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
        }

        _axios({
          url: locationUrl,
          method: 'GET',
          responseType: 'blob', // Important
        }).then(response => {
          FileDownload(response.data, 'List.xlsx');
        });
      } else {
        state.diagnosisList.forEach(el => {
          if (el.isChecked) {
            targetList += `${el.idx},`;
          }
        });
        let tempobj = {
          idx_leadership_group: state.ResistIdx,
          status: state.searchObj.searchStatus,
          idx: state.allCheck ? '' : targetList,
        };
        tempobj[state.searchObj.searchType] = state.searchObj.searchText;
        await _axios
          .get(`/admin/diag/list/excel`, {
            params: tempobj
          })
          .then(res => {
            if (res.status == 200) {
              if (!state.allCheck) {
                if (location.origin.includes('local') || location.origin.includes('stage')) {
                  location.href = `http://stage-api.ktngleader.com/admin/diag/list/excel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
                } else {
                  location.href = `https://api.ktngleader.com/admin/diag/list/excel?idx=${targetList}&idx_leadership_group=${state.ResistIdx}&${state.searchObj.searchType}=${state.searchObj.searchText}`;
                }
              } else {
                if (location.origin.includes('local') || location.origin.includes('stage')) {
                  location.href = `http://stage-api.ktngleader.com/admin/diag/list/excel?idx_leadership_group=${state.ResistIdx}`;
                } else {
                  location.href = `https://api.ktngleader.com/admin/diag/list/excel?idx_leadership_group=${state.ResistIdx}`;
                }
              }
            }
          })
          .catch(err => {
            alert(`다운로드에 실패하였습니다. 관리자에게 문의하세요 \n ${err}`);
          });
      }
    }

    function clickTab($event) {
      state.searchObj.searchMenu = $event.target.textContent;
      if (state.searchObj.searchMenu == '진단대상자별') {
        state.searchObj.searchType = 'diag_subject_name';
        getSubjectList(route.query.idx);
      } else {
        state.searchObj.searchType = 'diag_subject_name';
        getList(route.query.idx);
      }
    }
    function chagneFilter($event) {
      state.searchObj.searchType = $event.target.value;
    }
    function initInputData() {
      // state.groupValues.groupManagerEmail = '';
      state.groupValues.groupManagerName = '';
      // state.groupValues.groupManagerPhone = '';
      // state.groupValues.groupManagerEmail = '';
      state.groupValues.groupManagerEmailTtile = '';
      state.groupValues.groupEndData = '';
      state.groupValues.groupName = '';
      state.groupValues.groupStartData = '';
    }
    function setAnnouncementModal(textType, diagnosisType, messageText) {
      state.announcementType = textType;
      state.diagnosisType = diagnosisType;
      state.messageText = messageText;
      state.isAnnouncementModal = true;
    }
    async function guideSend() {
      const sendList = Array.from(state.checkList).join(',');
      if (state.announcementType === '메일') {
        const result = await _axios.post('/admin/diag/sendDiagMailOnly', { idx_leadership_group: Number(state.ResistIdx), idxList: sendList }, { });
        if (result.data.success) {
          alert(result.data.message);
          state.isAnnouncementModal = false;
        }
        return;
      }

      if (state.announcementType === '문자') {
        const result = await _axios.post(
          '/admin/diag/sendDiagMmsOnly',
          { idx_leadership_group: Number(state.ResistIdx), idxList: sendList, message_txt: state.messageText },
          {}
        );
        if (result.data.success) {
          alert(result.data.message);
          setMessageModal(false);
          state.isAnnouncementModal = false;
        }
        return;
      }

      if (state.announcementType === '미진단') {
        // try {
        if (state.sendChk) {
          state.sendChk = false;
          const sendList = Array.from(state.checkList).join(',');

          const postRes = await _axios.post(
            '/admin/diag/sendDiagMail',
            {
              idxList: sendList,
              idx_leadership_group: Number(state.ResistIdx),
              type: '미진단',
            },
            headers
          );
          if (postRes.data.success) {
            alert(postRes.data.message);
            setMessageModal(false);
            state.isAnnouncementModal = false;
          }
          state.sendChk = true;
        }
        // }
        // catch (err) {
        //   alert(`발송에 실패하였습니다 \n 관리자에게 문의하세요 \n ${err}`);
        //   state.sendChk = true;
        // }
        return;
      }
    }

    const onMovePageing = pageNumber => {
      // 페이지 이동 시 체크박스 초기화
      state.checkList.clear();
      state.allCheck = false;

      // 페이지 번호 설정
      state.pagingObj.pageNum = pageNumber;
      setPage.value = pageNumber;

      // 검색 메뉴에 따라 검색 함수 호출
      state.searchObj.searchMenu == '진단대상자별' ? searchSubhList() : searchList();
    };

    const searchList = async () => {
      await getList(route.query.idx);
    };

    const searchSubhList = async () => {
      await getSubjectList(route.query.idx);
    };

    const dateClick = $event => {
      $event.target.showPicker();
    };

    watch(
      () => route.query,
      async cur => {
        if (window.location.href.includes('Assignment') && window.location.href.includes('idx')) {
          //
          state.isModify = true;
          await getGroup(route.query.idx);
          await getGroupList(route.query.idx);
          await getList(route.query.idx);
          state.ResistIdx = route.query.idx;
        } else if (window.location.href.includes('Assignment')) {
          state.isModify = false;
          initInputData();
          state.statistics = {};
          state.diagnosisList = [];
        }
      }
    );

    onMounted(async () => {
      if (route.query.idx !== undefined) {
        state.isModify = true;
        await getGroup(route.query.idx);
        await getGroupList(route.query.idx);
        await getList(route.query.idx);
        state.ResistIdx = route.query.idx;
      }
    });

    return {
      state,
      addStarNumber,
      insertGroup,
      changeFile,
      clickChkBox,
      allChkBox,
      deleteItem,
      excelDownLoad,
      sampleExcelDwonLoad,
      setMessageModal,
      clickTab,
      chagneFilter,
      guideSend,
      setAnnouncementModal,
      onMovePageing,
      setPage,
      getGroup,
      searchList,
      searchSubhList,
      dateClick,
    };
  },
};
</script>

<style lang="scss" scoped>
.ta_info {
  margin-bottom: 50px;
}
table.ta_info tr th {
  width: 110px;
  height: 50px;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  text-align: center;
}
table.ta_info td {
  width: 110px;
  height: 50px;
  text-align: center;
  border: 1px solid #dee2e6;
}
article {
  margin-bottom: 50px;
}
.search-box {
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
}
.inli {
  display: inline-block;
}

.underline {
  text-decoration: underline;
  color: rgb(64, 64, 253);
}
.btnCl {
  margin: 15px;
  text-align: center;
}
.txtRed {
  color: #ff0000;
}
.txtBlue {
  color: blue;
}
.pointer {
  cursor: pointer;
}
.wrapper {
  max-width: 1500px;
}
.font-set {
  font-size: 13px;
}
.text-pd {
  padding: 20px;
}
.subText {
  font-size: 8px;
  color: gray;
}
.inputPadding {
  padding: 10px 0px 10px 10px;
}
.contact-information-form {
  span {
    height: 14px;
    display: block;
  }
}
</style>
