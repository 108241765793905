<template>
  <div v-if="visible" class="modal">
    <div v-if="state.count" class="modal_body">
      <div class="txt-right header">
        <img @click="clickCloseModal()" src="@/assets/images/icons/close.svg" alt="close-icon" class="cursor" />
      </div>
      <div class="mail-body">
        <div>
          <div>{{ kind === '미진단' ? `미진단자에게 메일, 문자로 모두 발송됩니다.` : `총 ${state.count}  건의 ${kind === '메일' ? `${kind}이` : `${kind}가`}   발송됩니다.` }}</div>
          <div>발송하시겠습니까?</div>
        </div>
        <div class="buttons">
          <b-button variant="outline-danger" class="button-margin" @click="clickCloseModal()">취소</b-button>
          <b-button variant="outline-success" @click="clickSendMassage()">확인</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { _axios } from '@/utils/axios';

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    kind: {
      type: String,
      required: false,
      default: false,
    },
    count: {
      type: Object,
      required: false,
      default: false,
    },
  },
  emits: ['closeModal', 'sendMassage'],
  setup(_, cotext) {
    const state = reactive({
      count: 0,
    });
    const store = useStore();
    const route = useRoute();

    const clickCloseModal = () => {
      cotext.emit('closeModal');
    };
    const clickSendMassage = () => {
      cotext.emit('sendMassage');
    };

    const init = async () => {
      const result = await _axios.get(`/admin/diag/sendDiagTargetCount`, {
        params: {
          idx_leadership_group: Number(route.query.idx),
          idxList: Array.from(_.count).join(','),
        }
      });

      if (result.data.success) {
        state.count = result.data.data.totalCnt;
      }
    };

    onMounted(() => {
      init();
    });

    return {
      state,
      clickCloseModal,
      clickSendMassage,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 10%;
}
.mail-body {
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .buttons {
    margin-top: 32px;
    .button-margin {
      margin-right: 84px;
    }
  }
}
</style>
