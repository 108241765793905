<template>
  <div v-if="visible" class="modal">
    <div v-show="visible && !isAnnouncementModal" :style="{ height: '500px', width: '400px' }" class="modal_body">
      <div v-if="state.count" class="body_wrap">
        <div class="header">
          <div style="width: 16px"></div>
          <div>SMS 안내 발송</div>
          <img @click="clickCloseModal()" src="@/assets/images/icons/close.svg" alt="close-icon" class="cursor" />
        </div>
        <div class="message-body">
          <div class="info">
            <div>※ {이름} : 받는 사람 이름</div>
            <div>※ {진단코드} : 각 진단자 진단코드</div>
            <div>※ {진단주소} : 각 고객사 진단주소</div>
          </div>
          <table>
            <tr>
              <th>발신번호</th>
              <td>02-518-5467</td>
            </tr>
            <tr>
              <th>수신자</th>
              <td>
                {{ checkList.size === 0 ? '전체' : checkList.size === 1 ? findName() : `${findName()} 외 ${state.count - 1} 명` }}
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <textarea v-model="state.messageText" cols="30" rows="10"></textarea>
                <div v-if="state.messageText.length <= 90" class="short-text">단문전송 {{ state.messageText.length }}/90</div>
                <div v-else :class="{ 'color-red': state.messageText.length > 2000 }" class="short-text">장문전송 {{ state.messageText.length }}/2000</div>
              </td>
            </tr>
          </table>
        </div>
        <div class="footer">
          <b-button variant="outline-danger" class="button-margin" @click="clickCloseModal()">취소</b-button>
          <b-button variant="outline-success" @click="clickSendMassage()">확인</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useNotification } from '@kyvg/vue3-notification';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { _axios } from '@/utils/axios';

export default {
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    checkList: {
      type: Object,
      required: false,
      default: false,
    },
    diagnosisList: {
      type: Array,
      required: false,
      default: false,
    },
    groupValues: {
      type: Object,
      default: false,
      required: false,
    },
    isAnnouncementModal: {
      type: Boolean,
    },
  },
  emits: ['closeModal', 'sendMassage'],

  setup(_, cotext) {
    const notification = useNotification();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      messageText: `[${_.groupValues.companyName}
${_.groupValues.groupNameShow}]
 
안녕하세요, {이름}님!
 
${_.groupValues.companyName}에서는 관리자분들의 리더십 역량 개발을 목적으로
본인, 상사, 동료(관리자) 및 구성원들이 참여하는 리더십 역량진단을 시행하고 있습니다.
여러분의 소중한 의견은 진단결과의 타당도와 신뢰도를 높여 관리자분들의 리더십 역량 향상에 도움이 됩니다.
 
진단코드: {진단코드}
진단주소 : ${_.groupValues.companySubDomain}.carrotleaders.com
기간: {startData} ~ {endData}
 
별도의 앱 다운로드 없이, 상기 주소 링크 클릭하시면 모바일 환경에서도 해당 진단에 참여하실 수 있습니다. 감사합니다.
 
진단문의: ${_.groupValues.contactEnquiry}`,
      count: 0,
    });

    const findName = () => {
      return _.diagnosisList.find(item => item.idx === Array.from(_.checkList)[0]).diag_name;
    };

    const clickCloseModal = () => {
      cotext.emit('closeModal');
    };

    const clickSendMassage = () => {
      if (state.messageText.length > 2000) {
        notification.notify({
          title: '텍스트 길이를 확인해주세요.',
          type: 'error',
        });
        return;
      }
      cotext.emit('sendMassage', '문자', '진단', state.messageText);
      //   cotext.emit('closeModal');
    };

    const init = async () => {
      const result = await _axios.get(
        `/admin/diag/sendDiagTargetCount`,

        {
          params: {
            idx_leadership_group: Number(route.query.idx),
            idxList: Array.from(_.checkList).join(','),
          },
        }
      );

      if (result.data.success) {
        state.count = result.data.data.totalCnt;
      }

      // data 에 props 를 직접 넣으려고 했지만, 진행 되지않아, 다음과 같이 처리
      const replaceStartText = state.messageText.replace('{startData}', _.groupValues.groupStartData);
      const replaceEndText = replaceStartText.replace('{endData}', _.groupValues.groupEndData);
      state.messageText = replaceEndText;
    };

    onMounted(() => {
      init();
    });

    return {
      clickCloseModal,
      clickSendMassage,
      state,
      findName,
    };
  },
};
</script>

<style lang="scss" scoped>
.body_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .header {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }
  .message-body {
    .info {
      margin-bottom: 6px;
      color: #ff0000;
      display: flex;
      flex-direction: column;
      align-items: end;
      font-size: 13px;
    }
    width: 100%;
    table {
      width: 100%;
      th {
        border: 1px solid #dee2e6;
        background-color: #f0f3f8;
        width: 100px;
        height: 32px;
      }
      td {
        .short-text {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 14px;
        }
        .color-red {
          color: #dc3545;
        }
        textarea {
          width: 100%;
          font-size: 14px;
          border: 1px solid transparent;
          padding: 0 8px;
          //   border-bottom: 1px solid #e4e4e4;
          resize: none;
          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
